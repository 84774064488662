.equipmentButton {
  border-radius: 100px;
  color: white;
  background-color: var(--mantine-color-myblue-5);
  font-family: Anton;

  &:hover {
    color: var(--mantine-color-myblue-5);
    background-color: white;
    outline: var(--mantine-color-myblue-5);
    outline-width: 1rem;
    outline-style: auto;
  }
}

.equipmentButtonText {
  border-radius: 100px;
  font-family: Anton;
}
